import React from 'react';

import { useRecoilState } from 'recoil';
import { atomToastMessage } from '../../atoms/atomToastMessage';

import { IToastMessage } from '../../globals/interfaces/IToastMessage';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

export interface IToastProps {}
export interface IToastState {}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export default function Toast(props: IToastProps) {
	//
	// state and initialisation

	const [isOpen, setIsOpen] = React.useState<boolean>(false);

	const [toastMessage, setToastMessage] = useRecoilState<IToastMessage>(atomToastMessage);

	// component mount --------------------------------------------------------

	React.useEffect(() => {
		if (toastMessage.message !== '') {
			// show toast message
			setIsOpen(true);
		}
	}, [toastMessage]);

	// helper functions -------------------------------------------------------

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setToastMessage({ message: '', severity: 'success' });
		setIsOpen(false);
	};

	// component render -------------------------------------------------------
	//
	// nb: snackbar is displayed for 2s on success message; if severity is other than
	//	   success, the snackbar needs to be closed by the end user!
	//

	if (toastMessage.message === '') {
		// nothing to do
		return null;
	}

	return (
		<Snackbar
			open={isOpen}
			autoHideDuration={toastMessage.severity === 'success' ? 2000 : null}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			onClose={handleClose}>
			<Alert onClose={handleClose} severity={toastMessage.severity} sx={{ width: '100%' }}>
				{toastMessage.message}
			</Alert>
		</Snackbar>
	);
}
