import { atom, selector } from 'recoil';
import { INote } from '../globals/interfaces/INote';
import { getValueFromLocalStorage } from './atomFunctions';
import { atomNoteList } from './atomNoteList';

import { KEY_LASTNOTEID } from '../globals/constants.ts/constants';

export const atomCurrentNote = atom<INote | null>({
	key: 'atomCurrentNote',
	default: null,
	effects: [
		// save current note ID in local storage for retrieval next time
		({ onSet }) => {
			onSet((newValue: INote | null) => {
				// save or remove
				if (newValue) {
					// set current note id in local store
					window.localStorage.setItem(KEY_LASTNOTEID, newValue.id);
				} else {
					// current note = null > remove local storage key
					window.localStorage.removeItem(KEY_LASTNOTEID);
				}
			});
		},
		// debug message in development only
		({ onSet }) => {
			onSet((newValue: INote | null) => {
				if (process.env.REACT_APP_DEBUGMODE) {
					console.log('atom > atomCurrentNote > set', newValue);
				}
			});
		},
	],
});

export const selectorCurrentNote = selector<INote | null>({
	key: 'selectorCurrentNote',
	get: ({ get }) => {
		// get list
		let _list: INote[] = get(atomNoteList),
			_noteId: string | null = getValueFromLocalStorage(KEY_LASTNOTEID),
			_result: INote | null = null;
		if (_noteId && _noteId !== '') {
			// get item index
			let _index: number = _list.findIndex((_note: INote) => _note.id === _noteId);
			// get item
			if (_index > -1) {
				_result = _list[_index];
			}
		}
		// return result
		return _result;
	},
});
