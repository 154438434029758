import { atom } from 'recoil';
import { INote } from '../globals/interfaces/INote';

export const atomNoteList = atom<INote[]>({
	key: 'atomNoteList',
	default: [],
	effects: [
		// debug message in development only
		({ onSet }) => {
			onSet((newValue: INote[]) => {
				if (process.env.REACT_APP_DEBUGMODE) {
					console.log('atom > atomNoteList > set', newValue);
				}
			});
		},
	],
});
