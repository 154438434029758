import { atom, selector } from 'recoil';
import { IAppSettings, setInitialAppSettings } from '../globals/interfaces/IAppSettings';
import { getValueFromLocalStorage } from './atomFunctions';

import { KEY_SETTINGS } from '../globals/constants.ts/constants';

export const atomAppSettings = atom<IAppSettings>({
	key: 'atomAppSettings',
	default: getInitialValues(),
	effects: [
		// save current note ID in local storage for retrieval next time
		({ onSet }) => {
			onSet((newValue: IAppSettings) => {
				// save
				window.localStorage.setItem(KEY_SETTINGS, JSON.stringify(newValue));
			});
		},
		// debug message in development only
		({ onSet }) => {
			onSet((newValue: IAppSettings) => {
				if (process.env.REACT_APP_DEBUGMODE) {
					console.log('atom > atomAppSettings > set', newValue);
				}
			});
		},
	],
});

export const selectorAppSettings = selector({
	key: 'selectorAppSettings',
	get: ({ get }) => {
		return get(atomAppSettings);
	},
});

function getInitialValues(): IAppSettings {
	// get values from store
	let _values = getValueFromLocalStorage<IAppSettings | null>(KEY_SETTINGS, 'json'),
		_result: IAppSettings;
	// check
	if (_values === null) {
		// no values > get initial values
		_result = setInitialAppSettings();
	} else {
		_result = _values;
	}
	// return the application settings
	return _result;
}
