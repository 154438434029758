import React from 'react';

import { useSetRecoilState, useRecoilState } from 'recoil';
import { atomNoteList } from '../atoms/atomNoteList';
import { atomCurrentNote } from '../atoms/atomCurrentNote';
import { atomToastMessage } from '../atoms/atomToastMessage';
import { getValueFromLocalStorage } from '../atoms/atomFunctions';

import { KEY_LASTNOTEID, KEY_STOREID } from '../globals/constants.ts/constants';

import { TypedList } from '../globals/data/TypedList';
import { INote, getEmptyNote } from '../globals/interfaces/INote';
import { IToastMessage } from '../globals/interfaces/IToastMessage';
import { TYPEActionItem, TYPEDataAction, TYPEMenuItem } from '../globals/types/types';

import Navigation from '../components/navigation/Navigation';
import Toast from '../components/toast/Toast';
import ToastWrapper from '../components/editor/ToastWrapper';
import SettingsDialog from '../components/settings/SettingsDialog';

export interface IMainProps {}
export interface IMainState {}

export default function Main(props: IMainProps) {
	//
	// state and initialisation

	let dataHandler = React.useRef<TypedList<INote, keyof INote>>(new TypedList(KEY_STOREID, 'id'));

	const [noteList, setNoteList] = useRecoilState<INote[]>(atomNoteList);
	const [currentNote, setCurrentNote] = useRecoilState<INote | null>(atomCurrentNote);

	const initialOnStartNoteId = getValueFromLocalStorage<string | null>(KEY_LASTNOTEID);

	// other states
	const setToastMessages = useSetRecoilState<IToastMessage>(atomToastMessage);
	const [isSettingsDialogOpen, setIsSettingsDialogOpen] = React.useState<boolean>(false);

	// component mount --------------------------------------------------------

	React.useEffect(() => {
		// initialize note list
		setNoteList(dataHandler.current.notes());
		// get initial note to load
		if (initialOnStartNoteId) {
			// get note
			const _note: INote | undefined = dataHandler.current.note(initialOnStartNoteId);
			if (_note) {
				// last loaded note found > show it
				setCurrentNote(_note);
			} else {
				// remove last note id
				window.localStorage.removeItem(KEY_LASTNOTEID);
				// local storage note id could not be found
				setToastMessages({
					message:
						"We tried loading your last opened note. We couldn't find it, maybe it has been deleted from another computer?",
					severity: 'warning',
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// helper functions -------------------------------------------------------

	function _onSave(note: INote, action: TYPEDataAction): boolean {
		// innitialize toast message
		let _success: boolean = true,
			_toastMsg: IToastMessage = { message: '', severity: 'success' },
			_newCurrentNote: INote | null = { ...note };

		// check if note should be saved
		if (action === 'add') {
			// no current note > adding new
			_success = dataHandler.current.save(_newCurrentNote, 'add');
			if (_success) {
				_toastMsg.message = 'Note added.';
			} else {
				_toastMsg = { message: 'Error adding note!', severity: 'error' };
			}
		} else {
			// initialize new title of this note
			let _title: string = _newCurrentNote.title,
				_newTitleMatched: RegExpMatchArray | null = _newCurrentNote.body.match(/^(.*)$/m);
			// determine title
			if (_newTitleMatched && _newTitleMatched.length > 0) {
				// set title
				_title = _newTitleMatched[0].replaceAll('#', '').trim();
				// update note with new title
				_newCurrentNote.title = _title;
			}

			if (currentNote) {
				switch (action) {
					case 'update':
						if (
							_newCurrentNote.body !== currentNote.body ||
							_newCurrentNote.locked !== currentNote.locked ||
							_newCurrentNote.archived ||
							currentNote.archived ||
							_newCurrentNote.pinned !== currentNote.pinned
						) {
							_success = dataHandler.current.save(_newCurrentNote, 'update');
							if (_success) {
								_toastMsg.message = 'Note updated.';
							} else {
								_toastMsg = { message: 'Error updating note!', severity: 'error' };
							}
						}
						break;
					case 'delete':
						_success = dataHandler.current.save(_newCurrentNote, 'delete');
						if (_success) {
							_newCurrentNote = null;
							_toastMsg.message = 'Note deleted.';
						} else {
							_toastMsg = { message: 'Error deleting note!', severity: 'error' };
						}
						break;
					default:
						console.log('current action not implemented:', action);
				}
			}
		}
		if (_success) {
			// update state
			setNoteList(dataHandler.current.notes());
			setCurrentNote(_newCurrentNote);
		} else {
			setCurrentNote(null);
		}
		// user feedback
		setToastMessages(_toastMsg);
		// return success
		return _success;
	}

	function _handleMenuAction(note: INote | null, action: TYPEMenuItem | TYPEActionItem): void {
		// handle main navigation menu actions
		switch (action) {
			case 'add':
				// create new note and save it in data store
				_onSave(getEmptyNote(), 'add');
				break;
			case 'delete':
				// data handling actions > pass through
				_onSave(note as INote, 'delete');
				break;
			case 'close':
				// close the note > editor onBlur will save the current note
				setCurrentNote(null);
				break;
			case 'lock':
			case 'unlock':
				// toggle lock property in note
				let _updateNote: INote = { ...(note as INote) },
					_newLocked: boolean = !_updateNote.locked;
				// toggle property
				_updateNote = {
					..._updateNote,
					locked: _newLocked,
					archived: _newLocked ? false : _updateNote.archived,
					pinned: _newLocked ? false : _updateNote.pinned,
				};
				// update
				_onSave(_updateNote, 'update');
				break;
			case 'archive':
			case 'unarchive':
				// toggle archive property in note
				let _noteArchived: INote = { ...(note as INote) },
					_newArchive: boolean = !_noteArchived.archived;
				// toggle property
				_noteArchived = {
					..._noteArchived,
					archived: _newArchive,
					locked: _newArchive ? false : _noteArchived.locked,
					pinned: _newArchive ? false : _noteArchived.pinned,
				};
				// update
				_onSave(_noteArchived, 'update');
				break;
			case 'pin':
			case 'unpin':
				// toggle pinned property in note
				let _notePinned: INote = { ...(note as INote) },
					_newPinned: boolean = !_notePinned.pinned;
				// toggle property
				_notePinned = {
					..._notePinned,
					pinned: _newPinned,
					archived: _newPinned ? false : _notePinned.archived,
					locked: _newPinned ? false : _notePinned.locked,
				};
				// update
				_onSave(_notePinned, 'update');
				break;
			case 'set_current':
				setCurrentNote(note);
				break;
			case 'settings':
				// toggle dialog settings
				setIsSettingsDialogOpen(!isSettingsDialogOpen);
				break;
			default:
				console.log('action not handled yet:', action);
		}
	}

	// component render -------------------------------------------------------

	return (
		<div className='app-root'>
			{/* main navigation elements: navigation bar, drawer bar, action menu */}
			<Navigation list={noteList} callback={_handleMenuAction} />
			{/* editor via wrapper */}
			<ToastWrapper note={currentNote} onSave={_onSave} />
			{/* settings dialog */}
			{isSettingsDialogOpen && (
				<SettingsDialog
					isOpen={isSettingsDialogOpen}
					callbackCloseDialog={() => _handleMenuAction(null, 'settings')}
				/>
			)}
			{/* message area */}
			<Toast />
		</div>
	);
}
