import { TYPELocalStoreValues } from '../globals/types/types';

/**
 * @function syncLocalStorage
 * @description Syncs local storage for passed key with new value
 * @param key Local storage key
 * @param settings Settings
 * @param newValue new value as key/value pair
 * @returns true for success, false if errors occur
 */
export function syncLocalStorage<T>(props: { key: string; settings: T; newValue: any }): boolean {
	// retrieve raw values from local storage
	let _rawValues: string | null = window.localStorage.getItem(props.key),
		_jsonValues: T | null = null,
		_success: boolean = true;

	if (_rawValues && _rawValues !== '') {
		try {
			// convert to object
			_jsonValues = JSON.parse(_rawValues);
			// update the value
			_jsonValues = { ..._jsonValues, ...props.newValue };
			// update local storage
			window.localStorage.setItem(props.key, JSON.stringify(_jsonValues));
		} catch (error) {
			// error > set return value to null
			_success = false;
		}
	}
	// return success
	return _success;
}

export function getValueFromLocalStorage<T>(
	key: string,
	type: TYPELocalStoreValues = 'text'
): T | null {
	// initialize
	let _result: T | null = null;
	// retrieve value from local storage
	try {
		let _rawValues: string | null = window.localStorage.getItem(key);
		// check
		if (_rawValues) {
			// convert to specified format
			if (type === 'json') {
				// jsonfy the results
				_result = JSON.parse(_rawValues) as T;
			} else {
				// just return the text values
				_result = _rawValues as T;
			}
		}
	} catch (error) {
		// error > set result
		_result = null;
	}
	// return results
	return _result;
}
