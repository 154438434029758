import React from 'react';

import { useRecoilValue } from 'recoil';
import { IAppSettings } from '../../globals/interfaces/IAppSettings';
import { atomAppSettings } from '../../atoms/atomAppSettings';

import { Editor, Viewer } from '@toast-ui/react-editor';
import { TYPEEditorMode } from '../../globals/types/types';

import toastStateReducer from './fnToastStateReducer';

import '@toast-ui/editor/dist/toastui-editor.css';
import './ToastEditor.scss';

export interface IToastEditorProps {
	value: string;
	noteId: string;
	onUpdateNote: (noteMarkdown: string) => boolean;
	editorMode?: TYPEEditorMode;
}
export interface IToastEditorState {
	value: string;
	noteId: string;
	mode: TYPEEditorMode;
	isDirty: boolean;
	isSpellcheckOn: boolean;
}

export default function ToastEditor(props: IToastEditorProps) {
	//
	// state and initialisation

	const refEditor = React.useRef<any>(null);
	const refCurrentNoteId = React.useRef<string>(props.noteId);

	const appSettings = useRecoilValue<IAppSettings>(atomAppSettings);

	const [stateEditor, dispatch] = React.useReducer(toastStateReducer, {
		value: props.value,
		noteId: props.noteId,
		mode: props.editorMode || 'view',
		isDirty: false,
		isSpellcheckOn: false,
	});

	// component mount --------------------------------------------------------

	React.useEffect(() => {
		// set editor markdown/wysiwyg tabs at the editor footer
		const _elm: HTMLElement | null = document.querySelector(':root');

		if (!_elm) {
			// nothing to do
			return;
		} else {
			// set
			_elm.style.setProperty(
				'--editor-show-tabs',
				appSettings.showEditorTabs ? 'block' : 'none'
			);
		}
	}, [appSettings.showEditorTabs]);

	React.useEffect(() => {
		// set contents in editor (force update)
		try {
			if (refEditor && refEditor.current) {
				// set editor contents
				refEditor.current.getInstance().setMarkdown(props.value, false);
				// render with new content
				dispatch({
					type: 'setInitialEditorValue',
					payload: {
						value: props.value,
						mode: props.editorMode,
					},
				});
				// update note reference
				refCurrentNoteId.current = props.noteId;
				// update copy HTML tag
				_setCopyHTMLtag();
			}
		} catch (error) {
			// show error
		}
	}, [props]);

	// helper functions -------------------------------------------------------

	function _onUpdateNote(): void {
		// set HTML and markdown references
		_setCopyHTMLtag();
		// execute save function in parent
		if (typeof props.onUpdateNote === 'function') {
			// save in parent
			props.onUpdateNote(stateEditor.value);
			// set flag
			dispatch({ type: 'toggleDirtyFlag', payload: {} });
		}
	}

	function _onEditorCustomKeyDown(type: any, event: KeyboardEvent): void {
		// check key combination > fire when Ctrl + S pressed
		if (typeof props.onUpdateNote === 'function') {
			if (event.ctrlKey && event.key.toLocaleLowerCase() === 's') {
				// prevent default key
				event.preventDefault();
				// save note
				_onUpdateNote();
			}
		}
	}

	function _setCopyHTMLtag(): void {
		// set HTML and markdown references
		const elmHTML: HTMLElement | null = document.getElementById('copyHTML');
		if (elmHTML) {
			// set markdown for copy references
			elmHTML.innerText = refEditor.current.getInstance().getHTML();
		}
	}

	// component render -------------------------------------------------------

	if (stateEditor.mode === 'view') {
		// editor in view mode
		return (
			<div>
				<Viewer ref={refEditor} initialValue={stateEditor.value} />
			</div>
		);
	}

	return (
		<div spellCheck={stateEditor.isSpellcheckOn} className=''>
			{/* editor in edit mode */}
			<Editor
				ref={refEditor}
				initialValue={stateEditor.value}
				initialEditType='wysiwyg'
				previewStyle='tab'
				height='91vh'
				useCommandShortcut={true}
				usageStatistics={false}
				onChange={() =>
					dispatch({
						type: 'setEditorValue',
						payload: {
							value: refEditor.current.getInstance().getMarkdown(),
						},
					})
				}
				onKeydown={_onEditorCustomKeyDown}
				onBlur={_onUpdateNote}
			/>
			{/* hidden elements contained HTML and markdown for copy purposes, set after onBlur event fires */}
			<div
				id='copyHTML'
				style={{
					visibility: 'hidden',
					height: '1px',
					position: 'absolute',
					top: 1,
					left: 1,
				}}></div>
		</div>
	);
}
