import React from 'react';

import { RecoilRoot } from 'recoil';
import Main from './Main';

import './App.css';

export default function App() {
	return (
		<RecoilRoot>
			<Main />
		</RecoilRoot>
	);
}
