//
//
//

import { IToastEditorState } from './ToastEditor';

export type TActionType =
	| 'setInitialEditorValue'
	| 'setEditorValue'
	| 'setEditorMode'
	| 'toggleEditorMode'
	| 'toggleSpellcheck'
	| 'toggleDirtyFlag';

export interface IToastAction {
	type: TActionType;
	payload: Partial<IToastEditorState>;
}

export default function toastStateReducer(
	state: IToastEditorState,
	action: IToastAction
): IToastEditorState {
	switch (action.type) {
		case 'setInitialEditorValue':
			return {
				...state,
				...action.payload,
				isDirty: false,
			};
		case 'setEditorMode':
			return { ...state };
		case 'toggleEditorMode':
			return {
				...state,
				mode: state.mode === 'view' ? 'edit' : 'view',
				isDirty: false,
			};
		case 'toggleSpellcheck':
			return {
				...state,
				isSpellcheckOn: state.isSpellcheckOn ? false : true,
			};
		case 'setEditorValue': // onchange of editor values
			if (state.mode === 'edit') {
				return {
					...state,
					...action.payload,
					isDirty: true,
				};
			} else {
				return { ...state };
			}
		case 'toggleDirtyFlag':
			// toggle dirty flag after save action, it triggers an update which
			// will toggle the state of several UI elements (buttons)
			return {
				...state,
				isDirty: !state.isDirty,
			};
		default:
			return state;
	}
}
