import { TYPEDrawerState, TYPENoteStore } from "../types/types";

export interface IAppSettings {
    showLocked: boolean;
    showArchived: boolean;
    showEditorTabs: boolean;
    allowSearch: boolean;
    drawerState: TYPEDrawerState;
    storeType: TYPENoteStore
}

export function setInitialAppSettings():IAppSettings {
    return {
        showArchived: true,
        showLocked: true,
        showEditorTabs: true,
        allowSearch: false,
        drawerState: 'temporary',
        storeType: 'localstorage'
    }
}