import React from 'react';

import { IAppSettings } from '../../globals/interfaces/IAppSettings';
import { useRecoilState } from 'recoil';
import { atomAppSettings } from '../../atoms/atomAppSettings';

import { INote } from '../../globals/interfaces/INote';
import { TYPEDrawerState, TYPEMenuItem } from '../../globals/types/types';

import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Search from './Search';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded';
import PushPinIcon from '@mui/icons-material/PushPin';
import WrongLocationOutlinedIcon from '@mui/icons-material/WrongLocationOutlined';

export interface IDrawerMenuProps {
	isOpen: boolean;
	list: INote[];
	currentNote: INote | null;
	callback?: (item: INote | null, action: TYPEMenuItem) => void;
}
export interface IDrawerMenuState {}

export default function DrawerMenu(props: IDrawerMenuProps) {
	//
	// state and initialisation

	const [appSettings, updateAppSettings] = useRecoilState<IAppSettings>(atomAppSettings);

	const [isOpen, setIsOpen] = React.useState<boolean>(props.isOpen);
	const [notesList, setNotesList] = React.useState<INote[]>(props.list);

	// component mount --------------------------------------------------------

	React.useEffect(() => {
		// toggle open drawer
		setIsOpen(props.isOpen);
	}, [props.isOpen]);

	React.useEffect(() => {
		// initialize list to use
		let _filteredList: INote[] = [...props.list];
		// filter list, based on app settings
		if (!appSettings.showArchived) {
			_filteredList = _filteredList.filter((_note: INote) => !_note.archived);
		}
		if (!appSettings.showLocked) {
			_filteredList = _filteredList.filter((_note: INote) => !_note.locked);
		}
		// update list
		setNotesList(_filteredList);
	}, [props.list, appSettings.showArchived, appSettings.showLocked]);

	// helper functions -------------------------------------------------------

	function _handleDrawerMenu(note: INote | null, action: TYPEMenuItem): void {
		if (typeof props.callback === 'function') {
			props.callback(note, action);
		}
	}

	function _handleSearch(searchString: string): void {
		if (searchString === '') {
			// TODO: clear search
		} else {
			// TODO: filter list based on search string
		}
	}

	function _handleClose(): void {
		// close drawer menu
		if (typeof props.callback === 'function') {
			props.callback(null, 'menu');
		}
	}

	function _handleDrawerStateChange(): void {
		// initialize
		const _newState: TYPEDrawerState =
			appSettings.drawerState === 'temporary' ? 'permanent' : 'temporary';
		// toggle drawer state from permanent to temporary
		updateAppSettings({
			...appSettings,
			drawerState: _newState,
		});
	}

	// helper components ------------------------------------------------------

	const NoteButtons = (): JSX.Element => {
		// return list
		return (
			<Stack direction='column' spacing={1}>
				{notesList.map((_note: INote) => {
					// determine icons displayed on button
					let _noteIcons: JSX.Element | null = null;
					if (_note.pinned) {
						_noteIcons = (
							<React.Fragment>
								<PushPinIcon fontSize='small' className='mui-icon-button-text' />
							</React.Fragment>
						);
					}
					if (_note.locked) {
						_noteIcons = (
							<React.Fragment>
								<KeyRoundedIcon fontSize='small' className='mui-icon-button-text' />
							</React.Fragment>
						);
					}
					if (_note.archived) {
						_noteIcons = (
							<React.Fragment>
								<ArchiveRoundedIcon
									fontSize='small'
									className='mui-icon-button-text'
								/>
							</React.Fragment>
						);
					}
					// return the note button
					return (
						<Button
							key={_note.id}
							variant={_note.id === props.currentNote?.id ? 'contained' : 'outlined'}
							style={{ justifyContent: 'flex-start', textTransform: 'initial' }}
							fullWidth
							// startIcon={_note.pinned ? <PushPinIcon /> : null}
							onClick={() => _handleDrawerMenu(_note, 'set_current')}>
							<Stack
								direction='row'
								justifyContent='space-between'
								sx={{ width: '100%' }}>
								<div className='drawer-note-button'>{_note.title}</div>
								<div>{_noteIcons}</div>
							</Stack>
						</Button>
					);
				})}
			</Stack>
		);
	};

	// component render -------------------------------------------------------

	return (
		<Drawer
			open={isOpen}
			anchor='left'
			variant={appSettings.drawerState}
			onClose={_handleClose}
			sx={{ width: { xs: '100vw', sm: '100vw', md: '250px' } }}>
			<Stack
				className='drawer-root'
				direction='column'
				justifyContent='space-between'
				sx={{ height: '90vh' }}>
				<Stack gap={1}>
					{/* toggle drawerstate temporary/permanent */}
					<Button
						aria-label='Switch drawer state'
						variant='text'
						style={{ justifyContent: 'flex-end' }}
						onClick={_handleDrawerStateChange}>
						{appSettings.drawerState === 'temporary' ? (
							<PushPinIcon />
						) : (
							<WrongLocationOutlinedIcon />
						)}
					</Button>
					{/* search box */}
					{appSettings.allowSearch && <Search handleSearch={_handleSearch} />}
					{/* note button list */}
					<NoteButtons />
				</Stack>
				<Stack gap={1}>
					{/* drawer button, bottom of drawer */}
					<Button
						variant='outlined'
						aria-label='menu'
						onClick={() => _handleDrawerMenu(null, 'add')}>
						<AddCircleOutlineRoundedIcon sx={{ mr: 1 }} />
						<Typography className='mui-icon-button-text'>Add</Typography>
					</Button>
				</Stack>
			</Stack>
		</Drawer>
	);
}
