import React from 'react';

import { TYPEMessage, TYPEMessagePlacement } from '../../globals/types/types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

export interface IMessageProps {
	text: string;
	type?: TYPEMessage;
	placement?: TYPEMessagePlacement;
}
export interface IMessageState {}

export default function Message(props: IMessageProps) {
	// set default message icon
	let _placement: TYPEMessagePlacement = props.placement || 'column',
		_messageIcon: JSX.Element = (
			<InfoOutlinedIcon fontSize='large' sx={{ mr: 1 }} color='primary' />
		);
	// determine icon for message
	switch (props.type) {
		case 'warning':
			_messageIcon = (
				<WarningAmberOutlinedIcon fontSize='large' sx={{ mr: 1 }} color='primary' />
			);
			break;
		default:
		// use default icon
	}
	// return centered message
	return (
		<Box display='flex' height='70vh' alignItems='center' justifyContent='center'>
			<Stack display='flex' direction={_placement} alignItems='center'>
				{_messageIcon}
				<Typography variant='body1' className='mui-icon-button-text'>
					{props.text}
				</Typography>
			</Stack>
		</Box>
	);
}
