//
// generic types class for handling lists
//

import { TYPEDataAction } from '../types/types';

export interface IFilterProps<K> {
	key: K;
	filter: any;
}

export interface IActiveSort {
	field: any;
	direction: 'asc' | 'desc';
}

export class TypedList<T, K extends keyof T> {
	// privates

	private _values: T[] = []; // list data
	private _localStorageKey: string = ''; // localstorage key to save list<T> in
	private _listPrimaryKey: K; // primary key of list to handle, e.g "id", "key", must be field in <T>
	private _activeSort: IActiveSort | null = null; // current sort, needed when read all action is provided to reset to sort
	// true = write to local storage
	private _writeFlag: boolean = true;
	// true = output to console window
	private _debugFlag: boolean = process.env.REACT_APP_DEBUGMODE === 'true';

	constructor(key: string, listPrimaryKey: K) {
		// save key
		this._localStorageKey = key;
		this._listPrimaryKey = listPrimaryKey;
		// get local storage
		let rawItemsFromStorage: string | null = window.localStorage.getItem(this._localStorageKey),
			items: T[] = [];
		// check
		if (rawItemsFromStorage) {
			// get list from json
			items = JSON.parse(rawItemsFromStorage);
		}
		// set values
		this._values = items;
	}

	// properties
	public notes = (): T[] => {
		return this._values;
	};

	public note = (id: string): T | undefined => {
		// get single item from list of items
		let value: T | undefined = this._values.find(
			(value: T) => value[this._listPrimaryKey] === id
		);
		// return the item
		return value;
	};

	//
	// additional methods: search, sort
	//

	// public search = <K extends keyof T>(term: any, keys: K[]): T[] => {
	// 	// get results
	// 	const results: T[] = _.filter(this._values, (value: T) => {
	// 		// initialize value to search
	// 		let searchText: string = '';
	// 		// build search string
	// 		keys.forEach((key: K) => {
	// 			searchText = `${searchText} ${value[key]}`;
	// 		});
	// 		// search
	// 		return searchText.toLowerCase().search(term.toLowerCase()) > -1;
	// 	});
	// 	// return results
	// 	return results;
	// };

	// public sort = <K extends keyof T>(key: K, direction: 'asc' | 'desc' = 'asc'): T[] => {
	// 	// sort current values
	// 	const results: T[] = lodashOrderBy(this._values, [key], [direction]);
	// 	// save sort
	// 	this._activeSort = { field: key, direction: direction };
	// 	// return results
	// 	console.log('results', results)
	// 	return results; 
	// };

	//
	// save: handles all C(R)UD operations - create, update and delete
	//

	public save = (item: T, action: TYPEDataAction): boolean => {
		// init
		let success: boolean = true;
		// perform action
		switch (action) {
			case 'add':
				success = this.createX(item);
				break;
			case 'update':
				success = this.updateX(item);
				break;
			case 'delete':
				success = this.deleteX(item[this._listPrimaryKey]);
				break;
			default:
				// action not supported
				this._debug(`ERROR: action not support: ${action}`);
				// return failure
				success = false;
		}
		// return save result
		return success;
	};

	//
	// crud operations > all private, crud done via save method
	//

	private createX = (item: T): boolean => {
		let success: boolean = true;
		try {
			// copy
			let _newList: T[] = [...this._values];
			// add
			_newList.push(item);
			// save
			this._values = _newList;
			// write
			success = this._write();
		} catch (error) {
			success = false;
		}
		return success;
	};

	private updateX = (item: T): boolean => {
		let success: boolean = true;
		try {
			// find item
			let itemIndex: number = this._values.findIndex(
					(value: T) => value[this._listPrimaryKey] === item[this._listPrimaryKey]
				),
				_currentList: T[] = [...this._values];
			// check
			if (itemIndex !== -1) {
				// update
				_currentList[itemIndex] = item;
				// set list
				this._values = _currentList;
				// write
				success = this._write();
			} else {
				// error
				success = false;
			}
		} catch (error) {
			success = false;
		}
		return success;
	};

	private deleteX = (id: any): boolean => {
		let success: boolean = true;
		try {
			// find item
			let itemIndex: number = this._values.findIndex(
					(value: T) => value[this._listPrimaryKey] === id
				),
				_currentList: T[] = [...this._values];
			// check
			if (itemIndex !== -1) {
				// delete
				_currentList.splice(itemIndex, 1);
				// set list
				this._values = _currentList;
				// write
				success = this._write();
			} else {
				// error
				success = false;
			}
		} catch (error) {
			success = false;
		}
		return success;
	};

	//
	// write to local storage
	//

	private _write = (): boolean => {
		try {
			if (this._writeFlag) {
				// save generic items to storage
				window.localStorage.setItem(this._localStorageKey, JSON.stringify(this._values));
			}
			// debug info
			this._debug(this._values, 'table');
		} catch (error) {
			// return failure
			return false;
		}
		// return success
		return true;
	};

	//
	// debugging
	//

	private _debug = (
		debugMsg: any,
		out: 'log' | 'table' = 'log',
		clear: boolean = false
	): void => {
		if (this._debugFlag) {
			if (clear) {
				// clear console > use only as first call
				console.clear();
			}
			switch (out) {
				case 'log':
					console.log(debugMsg);
					break;
				case 'table':
					console.table(debugMsg);
					break;
				default:
				// not supported
			}
		}
	};
}
