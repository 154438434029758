import React from 'react';

import { IAppSettings } from '../../globals/interfaces/IAppSettings';
import { useRecoilState } from 'recoil';
import { atomAppSettings } from '../../atoms/atomAppSettings';

import { TYPEDialogActions, TYPENoteStore } from '../../globals/types/types';
import GenericDialog from '../utils/GenericDialog';

import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';

export interface ISettingsDialogProps {
	isOpen: boolean;
	callbackCloseDialog: () => void;
}
export interface ISettingsDialogState {}

export default function SettingsDialog(props: ISettingsDialogProps) {
	//
	// state and initialisation

	const [appSettings, updateAppSettings] = useRecoilState<IAppSettings>(atomAppSettings);
	const [isOpen, setIsOpen] = React.useState<boolean>(props.isOpen);

	const DIALOG_TITLE: string = 'Settings';
	const DIALOG_TEXT: string =
		'Your settings are saved in browsers local storage. Note that your settings are not transferred to other browsers than this one.';

	// component mount --------------------------------------------------------

	React.useEffect(() => {
		// toggle dialog open/close
		setIsOpen(props.isOpen);
	}, [props.isOpen]);

	// helper functions -------------------------------------------------------

	function _handleDialog(action: TYPEDialogActions): void {
		// close dialog
		setIsOpen(false);
		// close dialog in parent call
		props.callbackCloseDialog();
		// check action
		if (action === 'ok') {
			// TODO: other actions for settings dialog
		}
	}

	function _updateSettingsValues(updatedSetting: Partial<IAppSettings>): void {
		// update app settings
		updateAppSettings({
			...appSettings,
			...updatedSetting,
		});
	}

	// component render -------------------------------------------------------

	return (
		<GenericDialog
			isOpen={isOpen}
			title={DIALOG_TITLE}
			text={DIALOG_TEXT}
			showCancelButton={false}
			callback={_handleDialog}>
			<FormGroup>
				{/* common settings */}

				<Typography sx={{ mt: 1, fontSize: '110%', fontWeight: 400 }}>
					Common Settings
				</Typography>

				<FormControlLabel
					control={
						<Checkbox
							defaultChecked={appSettings.showEditorTabs}
							size='small'
							onChange={(ev, checked: boolean) =>
								_updateSettingsValues({ showEditorTabs: checked })
							}
						/>
					}
					label='Show editor tabs'
				/>

				{/* settings drawer menu */}

				<Typography sx={{ mt: 1, fontSize: '110%', fontWeight: 400 }}>
					Drawer Menu
				</Typography>

				<Stack direction='column' sx={{ my: 0 }}>
					<FormControlLabel
						label='Show locked notes'
						control={
							<Checkbox
								defaultChecked={appSettings.showLocked}
								size='small'
								onChange={(ev, checked: boolean) =>
									_updateSettingsValues({ showLocked: checked })
								}
							/>
						}
					/>
					<FormControlLabel
						control={
							<Checkbox
								defaultChecked={appSettings.showArchived}
								size='small'
								onChange={(ev, checked: boolean) =>
									_updateSettingsValues({ showArchived: checked })
								}
							/>
						}
						label='Show archived notes'
					/>
					<FormControlLabel
						control={
							<Checkbox
								defaultChecked={appSettings.drawerState === 'permanent'}
								size='small'
								onChange={(ev, checked: boolean) =>
									_updateSettingsValues({
										drawerState: checked ? 'permanent' : 'temporary',
									})
								}
							/>
						}
						label='Show permanant drawer'
					/>
					<FormControlLabel
						control={
							<Checkbox
								defaultChecked={appSettings.allowSearch}
								size='small'
								onChange={(ev, checked: boolean) =>
									_updateSettingsValues({ allowSearch: checked })
								}
							/>
						}
						label='Allow search'
					/>

					{/* TODO: store usage, currently hidden */}

					<Typography sx={{ mt: 1, fontSize: '110%', fontWeight: 400 }}>
						Note Storage
					</Typography>

					<FormControl sx={{ mt: 2, display: 'inherit' }} disabled>
						<InputLabel id='select-store'>Store</InputLabel>
						<Select
							labelId='select-store'
							label='Store'
							id='select-store-dropdown'
							value={appSettings.storeType}
							onChange={(ev: SelectChangeEvent<TYPENoteStore>) =>
								_updateSettingsValues({
									storeType: ev.target.value as TYPENoteStore,
								})
							}>
							<MenuItem value=''>
								<em>None</em>
							</MenuItem>
							<MenuItem value={'localstorage'}>Local Storage</MenuItem>
							<MenuItem value={'indexeddb'}>IndexedDB</MenuItem>
							<MenuItem value={'firebase'}>Google Firebase</MenuItem>
						</Select>
					</FormControl>
				</Stack>
			</FormGroup>
		</GenericDialog>
	);
}
