//
// utility functions
//

/**
 * @function getID
 * @description Returns a (prefixed) unique ID
 * @param prefix optional, id prefix, default is empty string as prefix
 * @returns (Prefixed) unique ID
 */

export function getID(props?: { prefix: string }): string {
	// return the ID
	return `${props ? props.prefix : 'ID'}${Math.random().toString(16).slice(-8)}`;
}

/**
 * @function getRandomNumber
 * @description Returns a random integer between min and max values
 * @param min optional number, default = 0, floor value of return number
 * @param max optional number, default = 1000, ceiling value of return number
 * @returns A random number between min and max values
 */

export function getRandomNumber(props?: { min?: number; max?: number }): number {
	// pre check
	if (props && props.min !== undefined && props.max !== undefined) {
		if (props.min === props.max) {
			return props.min;
		}
	}
	// initialize
	let returnValue: number = 0,
		minValue: number = props && props.min ? props.min : 0,
		maxValue: number = props && props.max ? props.max : 1000;
	// get
	returnValue = minValue + Math.round(Math.random() * (maxValue - minValue));
	// return random number
	return returnValue;
}

/**
 *
 */
export function getRandomText(count: number = 20): string {
	// initialize
	let returnValue: string = '',
		baseString: string =
			'in varius bibendum scelerisque et netus et malesuada fames ac turpis egestas proin adipiscing posuere posuere duis iaculis rhoncus interdum nullam orci orci vivamus eu diam arcu ut porttitor in ullamcorper pretium iaculis quam a tincidunt gravida',
		baseArray: string[] = baseString.split(' '),
		wordCount: number = count;
	// iterate
	for (let index = 0; index < wordCount; index++) {
		// add
		returnValue =
			returnValue + baseArray[Math.round(Math.random() * baseArray.length - 1)] + ' ';
	}
	// return string
	return `${returnValue.substring(0, 1).toUpperCase()}${returnValue.substring(
		1,
		returnValue.length - 1
	)}.`;
}

/**
 * @function copyToClipboard
 * @description Copies passed text to the clipboard
 * @param text Text to copy to clipboard
 * @param callback Optional function to call when text has been copied
 * @returns Nothing
 */
export function copyToClipboard(text: string, callback?: () => void): void {
	// copy selected value to clipboard
	if (navigator && navigator.clipboard) {
		// browser support
		navigator.clipboard.writeText(text).then(() => {
			// action after copying
			if (typeof callback === 'function') {
				callback();
			}
		});
	}
}

/**
 * @function downloadContent
 * @description Download a file with the passed content
 * @param fileName Filename to use
 * @param content Content to save
 * @return Nothing
 */
export function downloadContent(fileName: string, content: string): void {
	// create new a element, the click on the 'a' tag triggers a file download with the passed contnet
	let element = document.createElement('a');
	element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
	element.setAttribute('download', fileName);
  	element.style.display = 'none';
	document.body.appendChild(element);
	// click element > trigger download
	element.click();
	// cleanup
  	document.body.removeChild(element);
}