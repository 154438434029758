import React from 'react';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

export interface ISearchProps {
	handleSearch: (searchString: string) => void;
	disabled?: boolean;
}
export interface ISearchState {}

export default function Search(props: ISearchProps) {
	//
	// state and initialisation

	// component mount --------------------------------------------------------

	// helper functions -------------------------------------------------------

	function _onChangeTriggerSearch(searchString: string): void {
		props.handleSearch(searchString);
	}

	// component render -------------------------------------------------------

	return (
		<TextField
			disabled={props.disabled}
			label=''
			variant='outlined'
			size='small'
			placeholder='Search'
			fullWidth
			InputProps={{
				startAdornment: (
					<InputAdornment position='start'>
						<SearchRoundedIcon />
					</InputAdornment>
				),
			}}
			onChange={ev => _onChangeTriggerSearch(ev.target.value)}
		/>
	);
}
