import React from 'react';

import { useRecoilValue } from 'recoil';
import { atomCurrentNote } from '../../atoms/atomCurrentNote';

import { INote } from '../../globals/interfaces/INote';
import { TYPEMenuItem, TYPEActionItem } from '../../globals/types/types';
import DrawerMenu from './DrawerMenu';
import ActionMenu from './ActionMenu';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';

import MenuIcon from '@mui/icons-material/Menu';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import AttractionsRoundedIcon from '@mui/icons-material/AttractionsRounded';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

export interface INavigationProps {
	list: INote[];
	callback?: (note: INote | null, action: TYPEMenuItem | TYPEActionItem) => void;
}
export interface INavigationState {}

export default function Navigation(props: INavigationProps) {
	//
	// state and initialisation

	const currentNote = useRecoilValue<INote | null>(atomCurrentNote);

	const [toggleDrawerOpen, setToggleDrawerOpen] = React.useState<boolean>(false);
	const [anchorActionMenu, setAnchorActionMenu] = React.useState<null | HTMLElement>(null);

	const sxTextProps = {
		display: { xs: 'none', sm: 'block' },
		mr: 1,
	};

	// component mount --------------------------------------------------------

	// helper functions -------------------------------------------------------

	function _handleAppbarMenu(menuItemType: TYPEMenuItem): void {
		// local handler
		switch (menuItemType) {
			case 'menu':
				// open drawer
				setToggleDrawerOpen(!toggleDrawerOpen);
				break;
			case 'add':
			case 'settings':
				// create note
				if (typeof props.callback === 'function') {
					// call parent handler
					props.callback(null, menuItemType);
				}
				break;
			default:
				// not implement
				console.log('action not implemented: ', menuItemType);
		}
	}

	function _handleDrawerMenu(note: INote | null, action: TYPEMenuItem): void {
		// close drawer
		setToggleDrawerOpen(false);

		if (action === 'add') {
			// add button
			_handleAppbarMenu(action);
		} else {
			// load note into editor
			if (typeof props.callback === 'function') {
				props.callback(note, action);
			}
		}
	}

	function _handleActionMenu(action: TYPEActionItem): void {
		// close menu
		setAnchorActionMenu(null);
		// only perform menu actions in the parent
		if (typeof props.callback === 'function') {
			// pass action to parent
			props.callback(currentNote, action);
		}
	}

	function _toggleActionMenu(event: React.MouseEvent<HTMLButtonElement>): void {
		if (anchorActionMenu) {
			// menu open > close it
			setAnchorActionMenu(null);
		} else {
			// toggle action menu
			setAnchorActionMenu(event.currentTarget);
		}
	}

	// component render -------------------------------------------------------

	return (
		<React.Fragment>
			<AppBar position='sticky' sx={{ zIndex: 1300 }}>
				<Toolbar>
					<Stack
						direction='row'
						alignItems='center'
						justifyContent='space-between'
						sx={{ width: '100%' }}>
						<div>
							<IconButton
								aria-label="Notes List"
								size='large'
								edge='start'
								color='inherit'
								onClick={() => _handleAppbarMenu('menu')}>
								<MenuIcon sx={{ mr: 1 }} />
							</IconButton>
							<IconButton color='inherit' onClick={() => _handleAppbarMenu('add')}>
								<AddCircleOutlineRoundedIcon sx={{ mr: 1 }} />
								<Typography sx={sxTextProps} className='mui-icon-button-text'>
									Add
								</Typography>
							</IconButton>
						</div>
						<Stack direction='row'>
							{/* action menu */}
							<IconButton
								disabled={currentNote === null}
								id='action_button'
								aria-controls={anchorActionMenu !== null ? 'basic-menu' : undefined}
								aria-haspopup='true'
								aria-expanded={anchorActionMenu !== null ? 'true' : undefined}
								onClick={_toggleActionMenu}
								color='inherit'>
								<AttractionsRoundedIcon sx={{ mr: 1 }} />
								<Typography sx={sxTextProps} className='mui-icon-button-text'>
									Actions
								</Typography>
							</IconButton>
							{/* action menu items */}
							<ActionMenu
								anchorRef={anchorActionMenu}
								handleClose={() => setAnchorActionMenu(null)}
								handleAction={_handleActionMenu}
							/>
							<IconButton
								color='inherit'
								onClick={() => _handleAppbarMenu('settings')}>
								<SettingsOutlinedIcon sx={{ mr: 1 }} />
								<Typography sx={sxTextProps} className='mui-icon-button-text'>
									Settings
								</Typography>
							</IconButton>
						</Stack>
					</Stack>
				</Toolbar>
			</AppBar>
			<DrawerMenu
				list={props.list}
				currentNote={currentNote}
				isOpen={toggleDrawerOpen}
				callback={_handleDrawerMenu}
			/>
		</React.Fragment>
	);
}
