import React from 'react';

import { useRecoilValue } from 'recoil';
import { atomCurrentNote } from '../../atoms/atomCurrentNote';

import { INote } from '../../globals/interfaces/INote';
import { TYPEActionItem } from '../../globals/types/types';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import KeyOffRoundedIcon from '@mui/icons-material/KeyOffRounded';
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded';
import UnarchiveRoundedIcon from '@mui/icons-material/UnarchiveRounded';
import CodeRoundedIcon from '@mui/icons-material/CodeRounded';
import FormatTextdirectionLToRRoundedIcon from '@mui/icons-material/FormatTextdirectionLToRRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import WrongLocationOutlinedIcon from '@mui/icons-material/WrongLocationOutlined';

export interface IActionMenuProps {
	anchorRef: null | HTMLElement;
	handleClose: () => void;
	handleAction: (action: TYPEActionItem) => void;
}
export interface IActionMenuState {}

export default function ActionMenu(props: IActionMenuProps) {
	//
	// state and initialisation

	const currentNote = useRecoilValue<INote | null>(atomCurrentNote);

	const disableDelete: boolean =
		currentNote?.archived || currentNote?.locked || currentNote?.pinned || false;

	// component mount --------------------------------------------------------

	// helper functions -------------------------------------------------------

	// component render -------------------------------------------------------

	return (
		<Paper>
			<Menu
				id='action_menu'
				anchorEl={props.anchorRef}
				open={props.anchorRef !== null}
				onClose={props.handleClose}
				MenuListProps={{ 'aria-labelledby': 'action_button' }}>
				<MenuItem onClick={() => props.handleAction('close')}>
					<ListItemIcon>
						<CloseRoundedIcon />
					</ListItemIcon>
					<ListItemText>Close</ListItemText>
				</MenuItem>
				<MenuItem onClick={() => props.handleAction('delete')} disabled={disableDelete} >
					<ListItemIcon>
						<DeleteOutlineRoundedIcon />
					</ListItemIcon>
					<ListItemText>Delete</ListItemText>
				</MenuItem>

				{/* toggle lock flag */}
				{currentNote?.locked ? (
					<MenuItem onClick={() => props.handleAction('unlock')}>
						<ListItemIcon>
							<KeyOffRoundedIcon />
						</ListItemIcon>
						<ListItemText>Unlock</ListItemText>
					</MenuItem>
				) : (
					<MenuItem onClick={() => props.handleAction('lock')}>
						<ListItemIcon>
							<KeyRoundedIcon />
						</ListItemIcon>
						<ListItemText>Lock</ListItemText>
					</MenuItem>
				)}

				{/* toggle archive flag */}
				{currentNote?.archived ? (
					<MenuItem onClick={() => props.handleAction('unarchive')}>
						<ListItemIcon>
							<UnarchiveRoundedIcon />
						</ListItemIcon>
						<ListItemText>De-Archive</ListItemText>
					</MenuItem>
				) : (
					<MenuItem onClick={() => props.handleAction('archive')}>
						<ListItemIcon>
							<ArchiveRoundedIcon />
						</ListItemIcon>
						<ListItemText>Archive</ListItemText>
					</MenuItem>
				)}

				{/* toggle pinned flag */}
				{currentNote?.pinned ? (
					<MenuItem onClick={() => props.handleAction('unpin')}>
						<ListItemIcon>
							<WrongLocationOutlinedIcon />
						</ListItemIcon>
						<ListItemText>Unpin</ListItemText>
					</MenuItem>
				) : (
					<MenuItem onClick={() => props.handleAction('pin')}>
						<ListItemIcon>
							<RoomOutlinedIcon />
						</ListItemIcon>
						<ListItemText>Pin</ListItemText>
					</MenuItem>
				)}

				<Divider />
				<MenuItem onClick={() => props.handleAction('copyhtml')}>
					<ListItemIcon>
						<CodeRoundedIcon />
					</ListItemIcon>
					<ListItemText>Copy HTML</ListItemText>
				</MenuItem>
				<MenuItem onClick={() => props.handleAction('copymarkdown')}>
					<ListItemIcon>
						<FormatTextdirectionLToRRoundedIcon />
					</ListItemIcon>
					<ListItemText>Copy Markdown</ListItemText>
				</MenuItem>
				<Divider />
				<MenuItem onClick={() => props.handleAction('expotmarkdown')}>
					<ListItemIcon>
						<FileDownloadRoundedIcon />
					</ListItemIcon>
					<ListItemText>Export Markdown</ListItemText>
				</MenuItem>
			</Menu>
		</Paper>
	);
}
