import { getID } from '../utils/fnUtils';

export interface INote {
	id: string;
	title: string;
	body: string;
	locked?: boolean;
	archived?: boolean;
	pinned?: boolean;
	color?: string;
	// auto generated properties
	created: Date;
	modified: Date;
}

export function getEmptyNote(item: Partial<INote> = {}): INote {
	// create
	let _time: string = new Date().toLocaleTimeString('nl'),
		_date: string = new Date().toLocaleDateString('nl'),
		_body: string = `Note, created @ ${_date} on ${_time}`,
		_newNote: INote = {
			id: getID({ prefix: 'NOTE' }),
			title: `Note - Created on ${_date} @ ${_time}`,
			body: _body,
			created: new Date(),
			modified: new Date(),
		};
	// check partial parameter
	if (item) {
		// add partial parameters
		_newNote = { ..._newNote, ...item };
	}
	// return
	return _newNote;
}
