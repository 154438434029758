import React from 'react';
import { TYPEDialogActions } from '../../globals/types/types';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

export interface IGenericDialogProps {
	isOpen: boolean;
	title: string;
	text?: string;
	showCancelButton?: boolean;
	children?: React.ReactNode;
	callback?: (action: TYPEDialogActions) => void;
}
export interface IGenericDialogState {}

export default function GenericDialog(props: IGenericDialogProps) {
	//
	// state and initialisation

	const [isOpen, setIsOpen] = React.useState<boolean>(props.isOpen);

	// component mount --------------------------------------------------------

	React.useEffect(() => {
		// toggle dialog open/close
		setIsOpen(props.isOpen);
	}, [props.isOpen]);

	// helper functions -------------------------------------------------------

	function _handleDialogAction(action: TYPEDialogActions): void {
		switch (action) {
			case 'ok':
			case 'cancel':
				// call parent
				if (typeof props.callback === 'function') {
					props.callback(action);
				}
				break;
			default:
				console.log('ERROR: Dialog action not handled', action);
		}
	}

	// component render -------------------------------------------------------

	return (
		<Dialog open={isOpen} onClose={() => _handleDialogAction('cancel')}>
			<DialogTitle>{props.title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{props.text}</DialogContentText>
				{props.children}
			</DialogContent>
			<DialogActions>
				<Button onClick={() => _handleDialogAction('ok')}>Ok</Button>
				{props.showCancelButton && (
					<Button onClick={() => _handleDialogAction('cancel')}>Cancel</Button>
				)}
			</DialogActions>
		</Dialog>
	);
}
