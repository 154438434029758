import { atom } from 'recoil';
import { IToastMessage } from '../globals/interfaces/IToastMessage';

export const atomToastMessage = atom<IToastMessage>({
	key: 'atomToastMessage',
	default: {message: '', severity: 'success'},
	effects: [
		// debug message in development only
		({ onSet }) => {
			onSet((newValue: IToastMessage) => {
				if (process.env.REACT_APP_DEBUGMODE) {
					console.log('atom > atomToastMessage > set', newValue);
				}
			});
		},
	],
});
