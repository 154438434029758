import React from 'react';

import { useRecoilValue } from 'recoil';
import { atomAppSettings } from '../../atoms/atomAppSettings';
import { IAppSettings } from '../../globals/interfaces/IAppSettings';

import { INote } from '../../globals/interfaces/INote';
import { TYPEDataAction, TYPEEditorMode } from '../../globals/types/types';

import ToastEditor from './ToastEditor';
import Message from '../utils/Message';

export interface IToastWrapperProps {
	note: INote | null;
	onSave: (note: INote, action: TYPEDataAction) => boolean;
}
export interface IToastWrapperState {}

export default function ToastWrapper(props: IToastWrapperProps) {
	//
	// state and initialisation

	const appSettings = useRecoilValue<IAppSettings>(atomAppSettings);

	const [wrapperNote, setWrapperNote] = React.useState<INote | null>(props.note);
	const [editorMode, setEditorMode] = React.useState<TYPEEditorMode>('view');

	const ROOT_MARGIN: number = appSettings.drawerState === 'temporary' ? 0 : 265;

	// component mount --------------------------------------------------------

	React.useEffect(() => {
		// set note in wrapper/editor
		setWrapperNote(props.note);
		setEditorMode(props.note?.locked || props.note?.archived ? 'view' : 'edit');
	}, [props.note]);

	// helper functions -------------------------------------------------------

	function _onUpdateWrapperNote(noteMarkdown: string): boolean {
		// check if note should be saved
		if (wrapperNote && noteMarkdown !== wrapperNote.body) {
			// save update markdown
			return props.onSave({ ...wrapperNote, body: noteMarkdown }, 'update');
		}
		// return success
		return true;
	}

	// component render -------------------------------------------------------

	if (wrapperNote === null) {
		// no note > show info message
		return <Message text='Create a note or select one in the drawer...' />;
	}

	return (
		<div
			className={`editor-root ${editorMode === 'view' ? 'editor-viewmode' : ''}`}
			style={{ marginLeft: `${ROOT_MARGIN}px` }}>
			<ToastEditor
				value={wrapperNote.body}
				noteId={wrapperNote.id}
				editorMode={editorMode}
				onUpdateNote={_onUpdateWrapperNote}
			/>
		</div>
	);
}
